import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import NavRuna from "../components/NavRuna";
import Footer from "../components/Footer";
export default function Esr() {
  return (
    <Container fluid className="g-0">
      <NavRuna />

      <Row className="justify-content-center g-0 py-5 runa-privacy">
        <Col xs={10} md={6} className="text-center my-5 py-5">
          <h4 className="primary-txt">
            RUNA YUCATÁN es un Desarrollo que pertenece y es respaldado por HADA
            Corp. ¡Conócenos!
          </h4>
          <p className="text-light">
            Bienvenidos a HADA Corp, hoy compartimos contigo nuestra Ética y
            Responsabilidad Social.
          </p>
        </Col>
      </Row>

      {/* SEGUNDA SECCIÓN POLITICAS DE PRIVACIDAD */}
      <Row className="justify-content-center py-5 g-0 gradiente-gris-down">
        <Col xs={10} md={6} className="my-5">
          <p className="secondary-txt">
            Al crear HADA Corp, pensamos detalladamente en los aspectos que
            regirían nuestra existencia. Y es que, como empresarios mexicanos,
            tenemos una gran responsabilidad no solo con nosotros mismos, sino
            con nuestra comunidad. Todo eso quedó definido desde el inicio en
            nuestro corazón corporativo, en dónde están plasmados nuestra
            misión, visión, valores y filosofía.
          </p>
          <p className="secondary-txt">
            Esa misma filosofía es la que transmitimos en nuestra cadena de
            valor con cada uno de nuestros clientes internos y externos:
          </p>
          <p className="secondary-txt">
            1.- Con los proveedores, ya que nos abastecen los suministros para
            los bienes o servicios.
            <br />
            2.- Con los colaboradores, quienes son los que nos ayudan en la
            producción de nuestros servicios bajo los estándares de calidad que
            nos rigen como ESR.
            <br />
            3.- Con los clientes, que son igual de importantes que los dos
            anteriores, pero es en ellos en quienes se refleja nuestra
            filosofía.
          </p>
          <p className="secondary-txt">
            Sabemos que, laborando de forma ética, lograremos satisfacer todas
            las expectativas. Con esto en mente, se estableció el reglamento,
            lineamientos y todos los aspectos en los que estamos involucrados
            todos los colaboradores de la empresa y clientes. Todo este trabajo
            está respaldado por nuestros asesores, abogados corporativos, el
            Comité de Ética y Directivos quienes marcan las normas, comunicación
            y valores.
          </p>
          <p className="secondary-txt">
            Si nuestros colaboradores, clientes y proveedores tienen los mismos
            principios y lineamientos, entonces nuestra comunidad estará
            satisfecha, porque esto desencadenará:
          </p>
          <p className="secondary-txt">
            <ul>
              <li>Generación de empleos en la empresa.</li>
              <li>Incremento de patrimonios.</li>
              <li>Aumento de turismo en la zona.</li>
              <li>
                Derrama económica en el estado y en los inversionistas, así como
                en los colaboradores.
              </li>
              <li>Generación de empleos en la zona.</li>
              <li>Respeto a los lineamientos.</li>
              <li>Creación de escuelas y hospitales.</li>
              <li>
                Nuevas comunidades sustentables con arquitectura del paisaje.
              </li>
              <li>Pago de impuestos.</li>
              <li>Creación de programas de apoyo.</li>
            </ul>
          </p>
          <p className="secondary-txt">
            En pocas palabras, una economía circular. Y lo más importante, es
            nuestro compromiso con el medio ambiente que incluye:
          </p>
          <p className="secondary-txt">
            <li>Separación de basura</li>
            <li>Capacitación de aguas pluviales</li>
            <li>Reforestación</li>
            <li>Plantas de tratamiento</li>
            <li>Tecnologías sustentables</li>
            <li>Energías renovables y sustentables</li>
            <li>Cuidado de parques</li>
            <li>Creación de huertos</li>
          </p>
          <p className="secondary-txt">
            Por lo que, en todo momento, siempre tenemos presente que somos una
            empresa con principios que no tiene final.
          </p>
          <p className="secondary-txt">
            ¡Ayúdanos a seguir creando y creciendo HADA Corp, ESR!
          </p>
        </Col>
      </Row>

      <Footer />
    </Container>
  );
}
